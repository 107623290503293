@font-face {
    font-family: "Roboto-Bold";
    src :url('../fonts/Roboto-Bold.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-Regular";
    src :url('../fonts/Roboto-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Akrobat-ExtraBold";
    src :url('../fonts/Akrobat-ExtraBold.ttf');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: "RobotoCondensed-Bold";
    src :url('../fonts/RobotoCondensed-Bold.ttf');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: "EB Garamond SemiBold Italic";
    src :url('../fonts/EB Garamond SemiBold Italic.ttf');
    font-weight: normal;
    font-style: normal;
}
.ffes{
    font-family: "EB Garamond SemiBold Italic";
}

.ffRB{
    font-family: "RobotoCondensed-Bold";
}

@keyframes scaleUpDown {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    to {
        opacity: 1;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.ffrb{
    font-family: "Roboto-Bold";
}
.ffrg{
    font-family: "Roboto-Regular";
}
.swiper{
    position:relative;
    &:after{
        content:"";
        display:block;
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        background:rgba(0,0,0,0.5);
        z-index:10;
    }
}
.home{
     background-size: cover;
     position:absolute;
     width:100%;
     height: 100%;
     overflow: hidden;
     &.tvPage{
        .main > .tac{
            margin-top: -5%;
        }
        .footer{
            padding-top: .28rem;
            .copyright{
                span{
                    display: block;
                }
            }
            &:after{
                content:"";
                display: block;
                position:absolute;
                top:0;
                left:.64rem;
                width:calc(100% - 1.28rem);
                border-top:1px solid rgba(#fff,0.2);
            }
            @media(max-width:1200px){
                .flex-item{
                    margin-right: .5rem !important;
                }
            }
            @media(max-width:1024px){
                .flex-item{
                    padding-top: .2rem;
                }
                .copyright{
                    text-align: center;
                    padding-top: .2rem;
                    span{
                        display: inline-block;
                    }
                }
            }
            @media(max-width:500px){
                padding-left: .3rem;
                padding-right: .3rem;
                &:after{
                    content:"";
                    display: block;
                    position:absolute;
                    top:0;
                    left:.3rem;
                    width:calc(100% - .6rem);
                    border-top:1px solid rgba(#fff,0.2);
                }
            }
        }
        .w240{
            width: 2.4rem;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        .w810{
            width: 8.1rem;
            margin-top: -.3rem;
            @media(max-width:900px){
                width:80%;
            }
        }
        .download{
            margin-top:.48rem;
            .download-item{
                width: 3.34rem;
                margin:0 .23rem;
                @media(max-width:600px){
                    margin:0 .1rem;
                }
            }
        }
     }
    .download{
        display:flex;
        align-items:center;
        margin-top:.64rem;
        justify-content: center;
        .download-item{
            width: 3.3rem;
            margin:0 .1rem;
            cursor: pointer;
            img{
                max-width:100%;
            }
        }
    }
    .swiper-container{
        position:absolute;
        width:100%;
        height: 100%;
        .img{
            width: 100%;
            height:100%;
            transition-origin:center center;
            object-fit: cover;
            transform:scale(1.1) translate(10px, 0);
            transition:transform 7.6s linear;
        }
        .swiper-slide-active{
            .img{
                transform:scale(1) translate(0px, 0);
                transition-delay:.2s;
                //animation: scaleUpDown 10.6s .3s forwards ease-out;
            }
        }
    }
    .main{
        height: 100%;
        position:absolute;
        left:0;
        top:0;
        z-index:1000;
        display:flex;
        align-items: center;
        width:100%;
        justify-content: center;
    }
}
.header-top{
    z-index:1000;
}
.footer{
    z-index:1000;
}
.link{
    color:#08c;
    cursor: pointer;
}
.copyright{
    @media(max-width: 750px){
        margin-top:15px;
    }
}
.line{
    position:absolute;
    bottom:7%;
    left:0;
    text-align: center;
    width:100%;

    span{
        width: 0.3rem;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        height: 0.06rem;
        background: #E8E8E8;
        position:relative;
        z-index:100;
        border-radius: 3px;
        margin:0 0.15rem;
        &.line-cur{
            background:var(--primary-color);
        }
    }
}


.wrapper{
    height:0.6rem;
    position:relative;
    display:flex;
    align-items:center;
    justify-content: center;
    font-size: 0.24rem;
    color:#212121;
    font-weight: 500;
    .logo img{
        width:0.6rem;
        position:absolute;
        left:0;
        top:0;
    }
}

.f-line{
    width:100%;
    height:1px;
    background:#E6E9F0;
    margin-top:0.4rem;
    margin-bottom: 5px;
}

.page-home{
    //background:url('../images/bg.png') no-repeat center center;
    background-size: cover;
}

.modal-title{
    span{
        font-size: 14px;
        color:#999;
        margin-left:10px;
    }
}

textarea{
    resize:none !important;
}

.ant-form-item-label > label{
    min-height:32px !important;
    height:auto !important;
}

.ffae{
    font-family: "Akrobat-ExtraBold";
}

.home-header{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    padding-top: .15rem;
    padding-bottom: .15rem;
    box-sizing: border-box;
    background: rgba(0,0,0,0.29) linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.59) 100%);
    z-index:1001;
    &.theme3{
        background:none;
        .left-box{
            img{
                width: .5rem;
                @media(max-width:600px){
                    width: .6rem;
                }
            }
            @media(max-width:600px){
               .fz20{
                   font-size:.28rem !important;
                   line-height: .4rem;
               }
            }
        }

        .home-header-center{
            width:auto;
            padding-left: 10.93%;
            padding-right:10.93%;
            @media(max-width:1300px){
                padding-left: 7%;
                padding-right: 7%;
            }

            a{
                padding:.14rem .24rem;
                background:transparent;
                border-radius:.2rem;
                &:hover{
                    color:#fff !important;
                    background:rgba(#fff,0.2);
                }
            }
        }
    }
    .home-header-center{
        width:1216px;
        margin-left: auto;
        margin-right: auto;
        @media(max-width:1300px){
            width:90%;
        }
        .top-link{
          @media(max-width:750px){
              display: none;
          }
        }
    }
}
.opa8{
    opacity: 0.82;
}
.home-style4{
    .main{
        padding-left: 10.93%;
        padding-right:10.93%;
        display: flex;
        justify-content: flex-end;
        @media(max-width:700px){
            align-items: baseline;
            padding-top:1.2rem;

        }
        .people{
            position:absolute;
            min-height:78.7%;
            width:44.27%;
            left:10.93%;
            bottom:6%;
            @media(max-width:1400px){
                left:5.93%;
            }
            @media(max-width:1100px){
                width:50%;
                //bottom:0;
                left:0;
                img{
                    position:absolute;
                    top:inherit !important;
                    right:inherit !important;
                }
            }
            @media(max-width:850px){
                width:44%;
            }
            @media(max-width:500px){
                width:70%;
            }
            img{
                position:absolute;
                left:0;
                top:0;
                right:0;
                bottom:0;
                //margin:auto;
                max-height:100%;
                max-width:100%;
            }
        }
        .download{
            justify-content: flex-end;
        }
    }
    .footer-homebox{
        position:absolute;
        width: 100%;
        right:0;
        bottom:.5rem;
        z-index:1112;
        text-align:center;
        @media(max-width:750px){
            text-align: center;
            bottom:.3rem;
        }
        .top-link{
            display: none;
            @media(max-width:750px){
                display: block;
                .mr77{
                    margin:0 .2rem;
                    font-size: .24rem;
                    font-family: PingFangSC-Semibold, PingFang SC;
                }
                a{
                    font-size: .24rem;
                    font-family: PingFangSC-Semibold, PingFang SC;
                }
                .mr77{
                    margin:0 .4rem;
                    font-size: .24rem;
                    font-family: PingFangSC-Semibold, PingFang SC;
                }
            }
        }
        .copyright{
            font-size: .16rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color:rgba(#fff,0.6);
            line-height: .26rem;
            margin-top: 0;
            @media(max-width:750px){
                margin-top:.15rem;
                font-size: .24rem;
            }
        }
    }
    @media(max-width:1300px){
        .main{
            padding-left: 7%;
            padding-right: 7%;
            .title{
                font-size: 1.2rem !important;
                line-height: 1.6rem !important;
            }
            .desc{
                font-size:.4rem !important;
            }
        }
    }
    .img{
        width:100%;
        height:100%;
    }
    .right-main{
        text-align: right;
        @media(max-width:700px){
            position:relative;
            z-index:200;
        }
    }
}
.home-style3{
    .main{
        padding-left: 10.93%;
        padding-right:10.93%;
        display: flex;
        justify-content: flex-end;
        @media(max-width:700px){
            align-items: baseline;
            padding-top:1.2rem;

        }
        .shu{
            position:absolute;
            min-height:78.7%;
            width:44.27%;
            left:2.1%;
            bottom:6%;
            @media(max-width:1024px){
                width:50%;
                //bottom:0;
                left:0;
                img{
                    position:absolute;
                    top:inherit !important;
                    right:inherit !important;
                }
            }
            @media(max-width:700px){
                width:70%;
            }
            @media(max-width:500px){
                width:80%;
            }
            img{
                position:absolute;
                left:0;
                top:0;
                right:0;
                bottom:0;
                //margin:auto;
                max-height:100%;
                max-width:100%;
            }
        }
        .download{
            justify-content: flex-end;
        }
    }
    .footer-homebox{
        position:absolute;
        width: 100%;
        right:0;
        bottom:.5rem;
        z-index:1112;
        text-align:center;
        @media(max-width:750px){
            text-align: center;
            bottom:.3rem;
        }
        .top-link{
            display: none;
            @media(max-width:750px){
                display: block;
                .mr77{
                    margin:0 .2rem;
                    font-size: .24rem;
                    font-family: PingFangSC-Semibold, PingFang SC;
                }
                a{
                    font-size: .24rem;
                    font-family: PingFangSC-Semibold, PingFang SC;
                }
                .mr77{
                    margin:0 .4rem;
                    font-size: .24rem;
                    font-family: PingFangSC-Semibold, PingFang SC;
                }
            }
        }
        .copyright{
            font-size: .16rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color:rgba(#fff,0.6);
            line-height: .26rem;
            margin-top: 0;
            @media(max-width:750px){
                margin-top:.15rem;
                font-size: .24rem;
            }
        }
    }
    @media(max-width:1300px){
        .main{
            padding-left: 7%;
            padding-right: 7%;
            .title{
                font-size: 1.2rem !important;
                line-height: 1.6rem !important;
            }
            .desc{
                font-size:.4rem !important;
            }
        }
    }
    .img{
        width:100%;
        height:100%;
    }
    .right-main{
        text-align: right;
        @media(max-width:700px){
            position:relative;
            z-index:200;
        }
    }
}
.home-style2{
    .img{
        width:100%;
        height:100%;
    }
    .footer-homebox{
        position:absolute;
        width: 1216px;
        right:0;
        left:50%;
        transform:translateX(-50%);
        bottom:.5rem;
        z-index:1112;
        text-align:right;
        @media(max-width:1300px){
            width: 90%;
        }
        @media(max-width:750px){
            text-align: center;
            bottom:.3rem;
        }
        .top-link{
            display: none;
            @media(max-width:750px){
                display: block;
                .mr77{
                    margin:0 .2rem;
                    font-size: .24rem;
                }
                a{
                    font-size: .24rem;
                }
                .mr77{
                    margin:0 .4rem;
                    font-size: .24rem;
                }
            }
        }
        .copyright{
            font-size: .18rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color:rgba(#fff,0.6);
            line-height: .25rem;
            margin-top: 0;
            @media(max-width:750px){
                margin-top:.15rem;
                font-size: .24rem;
            }
        }
    }
    .main{
        width: 1216px;
        left:50%;
        transform:translateX(-50%);
        bottom:0;
        display: block;
        height:83.6%;
        top:inherit;
        @media(max-width:1300px){
            width:90%;
        }
        @media(max-width:750px){
            height:100%;
        }
        .left-main{
            padding-top:.8rem;
            padding-right: 560px;
            position:relative;
            z-index:1111;
            @media(max-width:1300px){
                padding-right: 46.05%;
            }
            @media(max-width:750px){
                padding-right: 0;
            }
            .title{
                text-shadow: 0px 11px 36px rgba(0,0,0,0.5);
            }
        }
        .download{
            //display: block;
            text-align:left;
            justify-content: flex-start;
            .download-item{
                width: 3.3rem;
                margin-left: 0;
            }
        }
        .right-box{
            //width:560px;
            position:absolute;
            right:0;
            bottom:0;
            height:100%;
            @media(max-width:1300px){
                width:46.05%;
                height:auto;
            }
            @media(max-width:750px){
                width:40%;
            }
            @media(max-width:600px){
                width:65%;
                right:-5%;
            }
            img{
                width:100%;
               //@media(max-width:750px){
               //    position:absolute;
               //    bottom:0;
               //    right:0;
               //}
            }
        }
    }
    .footer-box{
        @media(max-width:750px){
            position:fixed;
            bottom:.3rem;
            left:0;
            width:100%;
            text-align: center;

        }
    }
}


.ffps{
    font-family: PingFangSC-Semibold, PingFang SC;
}
